<template>
  <div>
    <Head />

    <div class="wrapper new-case case-modal-btn">
      <a href="#" class="sr-only sr-only-focusable" id="AC" accesskey="C" name="C" title="主要內容"><img src="img/icon-circle.svg" alt=""></a>
      <section class="breadcrumb-box">
        <div class="container">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/" title="首頁">首頁</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                案件通報
              </li>
            </ol>
          </nav>
        </div>
      </section>

      <section class="main" v-if="CaseAddData">
        <div class="container">
          <img class="h1-img" src="/img/h1-case.svg" alt="" />
          <h1>案件通報</h1>
          <!-- 進度條 -->
          <div class="progress-box">
            <div class="progress">
              <div :class="Step == 1 ? 'active' : ''">通報案件</div>
              <div :class="Step == 2 ? 'active' : ''">個人資料</div>
              <div :class="Step == 3 ? 'active' : ''">完成</div>
            </div>
          </div>
          <!-- 內容 -->
          <div class="box" v-show="Step == 1">
            <h2
              class="icon-img"
              :style="
                'background-image: url(' +
                CaseAddData.SiteUrl +
                CaseAddData.CaseType.Image +
                ')'
              "
            >
              {{ CaseAddData.CaseType.Title }}
              <!--1019  -->
              <button
                class="favorite-icon"
                v-if="CaseAddData.User && !CaseAddData.IsFavorite"
                title="加入最愛"
                @click="setFavorite(true)"
              >
                <img src="/img/member-favorite.svg" alt="" />
              </button>
              <button
                class="favorite-icon"
                v-if="CaseAddData.User && CaseAddData.IsFavorite"
                title="取消最愛"
                @click="setFavorite(false)"
              >
                <img src="/img/member-favorite.png" alt="" />
              </button>
            </h2>
            <div class="text-box">
              <div class="form-group">
                <label for="Subj_Item">事項</label>
                <select class="form-control" id="Subj_Item" v-model="Subj_Item">
                  <option value="-1">請選擇要通報的事項</option>
                  <option
                    v-for="(SubCaseType, index) in CaseAddData.SubCaseTypes"
                    :key="index"
                    :value="SubCaseType.CaseType.Code"
                    :data-needident="SubCaseType.CaseType.NeedIdent ? '1' : '0'"
                  >
                    {{ SubCaseType.CaseType.Title }}
                  </option>
                </select>
              </div>

              <div
                v-for="(SubCaseType, index) in CaseAddData.SubCaseTypes"
                :key="index"
              >
                <div
                  class="form-group"
                  v-if="
                    SubCaseType.CaseType.Code == Subj_Item &&
                    SubCaseType.SubCaseTypes
                  "
                >
                  <label for="Subj_Subitem">次事項</label>
                  <select
                    class="form-control"
                    :id="'Subj_Subitem_' + SubCaseType.CaseType.Code"
                  >
                    <option
                      v-for="(
                        SubSubCaseType, index
                      ) in SubCaseType.SubCaseTypes"
                      :key="index"
                      :value="SubSubCaseType.Code"
                    >
                      {{ SubSubCaseType.Title }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group" v-if="CaseAddData.Areas">
                <label for="Subj_District">行政區</label>
                <select
                  class="form-control"
                  id="Subj_District"
                  v-model="Subj_District"
                >
                  <option
                    v-for="(Area, index) in CaseAddData.Areas"
                    :key="index"
                    :text="Area.Name"
                    :value="Area.AreaId"
                  >
                    {{ Area.Name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="Subj_Location">地點</label>
                <input
                  type=""
                  class="form-control"
                  id="Subj_Location"
                  placeholder="請利用地圖選擇(事件發生地址)，勿輸入地點以外的文字"
                  v-model="Subj_Location"
                  style="width: calc(100% - 210px)"
                />
                <a
                  href="javascript:void(0)"
                  style="margin-left: 10px; margin-bottom: 5px"
                  data-toggle="modal"
                  data-target="#mapModal"
                  ><img src="/img/address-icon.svg"
                /></a>
              </div>
              <div class="form-group" v-if="lat > 0 && lng > 0">
                <label for="Subj_LatLng">座標</label>
                <input
                  type=""
                  class="form-control"
                  id="Subj_LatLng"
                  placeholder=""
                  v-model="Subj_LatLng"
                  disabled="true"
                />
              </div>
              <hr />
              <div class="message-box">
                <label for="message">反應內容</label>
                <div class="message">
                  <textarea
                    id="Subj_Content"
                    cols=""
                    rows="6"
                    placeholder="請輸入反應內容"
                    v-model="Subj_Content"
                  ></textarea>
                  <p class="notice">
                    注意事項：
                    <br />
                    陳情內容如超過1,000字，請以附件檔案上傳。
                    <br />
                    為避免信件未順利送出，以致遺失陳情內容，建議可先備份。
                  </p>
                </div>
              </div>
              <hr />
              <div class="input-group">
                <p>文件</p>
                <div class="file">
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="file_1"
                      accept=".doc,.docx,.xls,.xlsx,.pdf,.txt,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.ods,.zip"
                      @change="fileChange($event, 1)"
                    />
                    <label class="custom-file-label" aria-describedby="">{{
                      UploadFileName_1
                    }}</label>
                    <button v-if="UploadFileId_1 != ''" @click="clearFile(1)">
                      刪除
                    </button>
                  </div>
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="file_2"
                      accept=".doc,.docx,.xls,.xlsx,.pdf,.txt,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.ods,.zip"
                      @change="fileChange($event, 2)"
                    />
                    <label
                      class="custom-file-label"
                      for="file_2"
                      aria-describedby=""
                      >{{ UploadFileName_2 }}</label
                    >
                    <button v-if="UploadFileId_2 != ''" @click="clearFile(2)">
                      刪除
                    </button>
                  </div>
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="file_3"
                      accept=".doc,.docx,.xls,.xlsx,.pdf,.txt,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.ods,.zip"
                      @change="fileChange($event, 3)"
                    />
                    <label
                      class="custom-file-label"
                      for="file_3"
                      aria-describedby=""
                      >{{ UploadFileName_3 }}</label
                    >
                    <button v-if="UploadFileId_3 != ''" @click="clearFile(3)">
                      刪除
                    </button>
                  </div>
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="file_5"
                      accept=".doc,.docx,.xls,.xlsx,.pdf,.txt,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.ods,.zip"
                      @change="fileChange($event, 5)"
                    />
                    <label
                      class="custom-file-label"
                      for="file_5"
                      aria-describedby=""
                      >{{ UploadFileName_5 }}</label
                    >
                    <button v-if="UploadFileId_5 != ''" @click="clearFile(5)">
                      刪除
                    </button>
                  </div>
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="file_6"
                      accept=".doc,.docx,.xls,.xlsx,.pdf,.txt,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.ods,.zip"
                      @change="fileChange($event, 6)"
                    />
                    <label
                      class="custom-file-label"
                      for="file_6"
                      aria-describedby=""
                      >{{ UploadFileName_6 }}</label
                    >
                    <button v-if="UploadFileId_6 != ''" @click="clearFile(6)">
                      刪除
                    </button>
                  </div>
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="file_7"
                      accept=".doc,.docx,.xls,.xlsx,.pdf,.txt,.bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.ods,.zip"
                      @change="fileChange($event, 7)"
                    />
                    <label
                      class="custom-file-label"
                      for="file_7"
                      aria-describedby=""
                      >{{ UploadFileName_7 }}</label
                    >
                    <button v-if="UploadFileId_7 != ''" @click="clearFile(7)">
                      刪除
                    </button>
                  </div>
                  <p class="notice">
                    注意事項：
                    <br />
                    <!-- 1.總上傳容量為{{ CaseAddData.LimitFileSize }}MB，<span
                      style="color: #0000ff"
                      >已上傳{{ UploadFileSize_Total }}MB</span
                    >，檔名建議使用半形英數字(A-Z,a-z,0-9)命名。 -->
                    1.總上傳容量為{{ CaseAddData.LimitFileSize }}MB，檔名建議使用半形英數字(A-Z,a-z,0-9)命名。
                    <br />
                    2.可上傳的文件檔案格式為.doc .docx .xls .xlsx .pdf .txt .bmp
                    .jpg .gif .tif .png .gif .jpeg .odt .ods ，其餘格式請壓縮成
                    zip 檔再上傳。
                  </p>
                </div>
              </div>
              <hr class="line" />
              <div class="input-group">
                <p>影片</p>
                <div class="file">
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="file_4"
                      accept=".mp4,.mov,.hevc"
                      @change="fileChange($event, 4)"
                    />
                    <label
                      class="custom-file-label"
                      for=""
                      aria-describedby=""
                      >{{ UploadFileName_4 }}</label
                    >
                    <button v-if="UploadFileId_4 != ''" @click="clearFile(4)">
                      刪除
                    </button>
                  </div>
                  <p class="notice">
                    注意事項：
                    <br />
                    1.總上傳容量為{{ this.CaseAddData.LimitVideoSize }}MB，<span
                      style="color: #0000ff"
                      >已上傳{{ UploadVideoSize_Total }}MB</span
                    >，檔名建議使用半形英數字(A-Z,a-z,0-9)命名。
                    <br />
                    2.可上傳的影片格式為.mp4、.mov、.hevc。
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <p class="notice">
              您所通報的案件，除個人資料以外的相關資訊(包含案件內容摘要、時間、地點等)，都將公布於本網站。
            </p>
            <div class="btn-box">
              <button @click="GoToStep(2)">下一步</button>
            </div>
          </div>
          <div class="box" v-show="Step == 2">
            <h2>個人資料</h2>
            <div class="text-box">
              <div class="form-group">
                <label for="Sugg_Name">真實姓名</label>
                <input
                  type=""
                  class="form-control"
                  id="Sugg_Name"
                  placeholder="請輸入真實姓名"
                  v-model="Sugg_Name"
                  :disabled="CaseAddData.User"
                />
              </div>
              <div class="form-group" v-if="NeedIdent">
                <label for="Sugg_Id">身分證號</label>
                <input
                  type=""
                  class="form-control"
                  id="Sugg_Id"
                  placeholder="請輸入身分證號"
                  v-model="Sugg_Id"
                />
              </div>
              <div class="form-group">
                <label for="Sugg_Sex">性別</label>
                <select
                  class="form-control"
                  id="Sugg_Sex"
                  v-model="Sugg_Sex"
                  :disabled="CaseAddData.User"
                >
                  <option value="1">女</option>
                  <option value="2">男</option>
                  <option value="3">其他</option>
                </select>
              </div>
              <div class="form-group">
                <label for="Sugg_Telno">手機號碼</label>
                <input
                  type="text"
                  class="form-control"
                  id="Sugg_Telno"
                  placeholder="請輸入手機號碼"
                  v-model="Sugg_Telno"
                  :disabled="CaseAddData.User"
                />
              </div>
              <div class="form-group">
                <label for="Sugg_Email">電子信箱</label>
                <input
                  type="text"
                  class="form-control"
                  id="Sugg_Email"
                  placeholder="請輸入電子信箱"
                  v-model="Sugg_Email"
                  :disabled="CaseAddData.User"
                />
              </div>
              <hr />
              <div class="form-group reply-box">
                <label for="">回復方式</label>
                <div class="reply">
                  <div
                    class="form-check"
                    v-if="
                      CaseAddData.CaseType.VerifiedType == 1 ||
                      CaseAddData.CaseType.VerifiedType == 2
                    "
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      name="Sugg_ReplyType"
                      id="Sugg_2"
                      value="2"
                      v-model="Sugg_ReplyType"
                    />
                    <label class="form-check-label" for="Sugg_2">
                      以簡訊方式傳送個資檢核碼及立案、結案相關資訊
                    </label>
                  </div>
                  <div
                    class="form-check"
                    v-if="
                      CaseAddData.CaseType.VerifiedType == 1 ||
                      CaseAddData.CaseType.VerifiedType == 3
                    "
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      name="Sugg_ReplyType"
                      id="Sugg_3"
                      value="3"
                      v-model="Sugg_ReplyType"
                    />
                    <label class="form-check-label" for="Sugg_3">
                      以電子郵件寄送個資檢核碼及立案、結案相關資訊
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group check-code" v-if="!CaseAddData.User">
                <label for="inputCode">個資檢核碼</label>
                <div class="code-box">
                  <input
                    type=""
                    class="form-control"
                    placeholder="請輸入個資檢核碼"
                    id="inputCode"
                    v-model="pincode_input"
                    @input="pincode_input_onInput"
                    :disabled="pincodeCheck"
                  />
                  <div class="" v-if="!pincodeCheck">
                    <button @click="getPinCode()">
                      {{ pincodeTitle }}
                    </button>
                    <button
                      onclick="window.open('/#/Post/1070');"
                      v-if="issned"
                    >
                      沒收到檢核碼?
                    </button>
                  </div>
                </div>
              </div>
              <hr v-if="!CaseAddData.User" />
              <div class="form-group reply-box" v-if="!CaseAddData.User">
                <label for="">加入會員</label>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="Register"
                    v-model="Register"
                  />
                  <label class="form-check-label" for="Register"
                    >通報後同時加入網站會員，勾選即同意加入網站會員條款</label
                  >
                </div>
              </div>
            </div>
            <hr />
            <p class="notice">
              您所通報的案件，除個人資料以外的相關資訊(包含案件內容摘要、時間、地點等)，都將公布於本網站。
            </p>
            <div class="btn-box">
              <button @click="GoToStep(1)">上一步</button>
              <button v-if="pincodeCheck" @click="SendCase()">送出</button>
            </div>
          </div>
          <div class="finish-box" v-show="Step == 3">
            <h2>案件編號：{{ CaseNo }}</h2>
            <div class="circle">
              <div>
                <p>完成通報</p>
              </div>
            </div>
            <p>
              您所通報的案件，除個人資料以外的相關資訊(包含案件內容摘要、時間、地點等)，都將公布於本網站。
            </p>
            <p style="font-weight: 900" v-if="Register">
              網站會員註冊成功，請透過上方選單進行登入，帳號為您的電子信箱，預設密碼為手機號碼
            </p>
          </div>
        </div>
      </section>

      <!-- 進入網頁自動彈跳視窗 使用說明 -->
      <div
        class="modal fade case-modal"
        id="mapModal"
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="title">
              <h2 class="">請選擇事件發生地址</h2>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <GmapMap
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: true,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUi: false,
                  styles: [
                    {
                      featureType: 'poi',
                      stylers: [{ visibility: 'off' }],
                    },
                    {
                      featureType: 'transit',
                      stylers: [{ visibility: 'off' }],
                    },
                  ],
                }"
                ref="mapRef"
                :zoom="17"
                style="width: 100%; height: 500px"
                :center="{ lat: cp_lat, lng: cp_lng }"
                @idle="updateCenter"
              >
                <GmapMarker
                  v-if="mark_position != null"
                  :position="mark_position"
                  :clickable="true"
                  :draggable="false"
                  @click="center = mark_position"
                  :icon="{
                    url: '/img/map-people-new.svg',
                  }"
                ></GmapMarker>
                <GmapMarker
                  v-if="false"
                  :position="center_position"
                  :clickable="false"
                  :draggable="false"
                ></GmapMarker>
                <GmapMarker
                  :key="index"
                  v-for="(Case, index) in Cases"
                  :position="{
                    lat: parseFloat(Case.Subj_Latitude),
                    lng: parseFloat(Case.Subj_Longitude),
                  }"
                  :clickable="true"
                  :draggable="false"
                  @click="toggleWindow(index)"
                  ><GmapInfoWindow :opened="window_open === index">
                    <div>
                      <p>類　　型：{{ Case.Subj_Item_Text }}</p>
                      <p v-if="Case.Subj_Subitem_Text">
                        事　　項：{{ Case.Subj_Subitem_Text }}
                      </p>
                      <p>地　　點：{{ Case.Subj_Location }}</p>
                      <p v-if="Case.Status == 1">案件狀態：已通報</p>
                      <p v-if="Case.Status == 2">案件狀態：通報中</p>
                      <p v-if="Case.Status == 3">案件狀態：處理中</p>
                      <p v-if="Case.Status == 4">案件狀態：已結案</p>
                    </div>
                  </GmapInfoWindow></GmapMarker
                >
              </GmapMap>
              <div
                class="centerMarker"
                style="
                  position: absolute;
                  background: url(../img/marker.png) no-repeat left top;
                  top: 50%;
                  left: 50%;
                  z-index: 999;
                  margin-left: -10px;
                  margin-top: -40px;
                  height: 42px;
                  width: 20px;
                  background-size: 20px 42px;
                  cursor: pointer;
                "
              ></div>
              <button
                style="
                  position: absolute;
                  right: 25px;
                  bottom: 125px;
                  z-index: 99;
                  width: 41px;
                  height: 41px;
                  background-color: white;
                  border: solid 1px;
                  border-color: #e9e9e9;
                  border-radius: 2px;
                  background-image: url(./img/myLocation.png);
                  background-size: contain;
                "
                @click="gotoMyLocation()"
              ></button>
              <!-- <div
                style="
                  width: auto;
                  height: auto;
                  border: 1px solid rgb(0 0 0);
                  position: absolute;
                  top: 25px;
                  right: 25px;
                  background: rgb(255 255 255);
                  padding: 5px 5px;
                  border-radius: 4px;
                  font-size: 14px;
                "
                v-if="CaseInfo"
              >
                <p>類　　型：{{ CaseInfo.Subj_Item_Text }}</p>
                <p v-if="CaseInfo.Subj_Subitem_Text">
                  事　　項：{{ CaseInfo.Subj_Subitem_Text }}
                </p>
                <p>地　　點：{{ CaseInfo.Subj_Location }}</p>
                <p v-if="CaseInfo.Status == 1">案件狀態：已通報</p>
                <p v-if="CaseInfo.Status == 2">案件狀態：通報中</p>
                <p v-if="CaseInfo.Status == 3">案件狀態：處理中</p>
                <p v-if="CaseInfo.Status == 4">案件狀態：已結案</p>
              </div> -->
            </div>
            <div class="modal-footer">
              <button type="button" class="" data-dismiss="modal">關閉</button>
              <button type="button" class="" @click="setAddress()">確認</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    //key: "AIzaSyBmY4-9JtWZoxAcuRZTGT5NrzZsyJTmQ-o",
    key: "AIzaSyAbIDDWw8Bl5Y1JSp6YiV_rcLH-3gtv-68",
    libraries: "places",
  },
  installComponents: true,
});

export default {
  name: "CaseAdd",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      Step: 1,
      CaseAddData: null,
      Subj_Item: "-1",
      Subj_District: "",
      Subj_Location: "",
      Subj_Location_Last: "",
      Subj_Content: "",
      Sugg_Name: "",
      Sugg_Id: "",
      Sugg_Sex: 1,
      Verify: false,
      Sugg_Telno: "",
      Sugg_Email: "",
      Register: false,
      Sugg_ReplyType: 2,
      pincode_input: "",
      pincode: "",
      pincodeCheck: false,
      pincodeTitle: "發送檢核碼",
      pincodeTime: 0,
      pincode_Sugg_ReplyType: -1,
      pincode_Sugg_Telno: "",
      pincode_Sugg_Email: "",
      CaseNo: "",
      UploadFileName_1: "選擇檔案",
      UploadFileId_1: "",
      UploadFileSize_1: 0,
      UploadFileName_2: "選擇檔案",
      UploadFileId_2: "",
      UploadFileSize_2: 0,
      UploadFileName_3: "選擇檔案",
      UploadFileId_3: "",
      UploadFileSize_3: 0,
      UploadFileName_4: "選擇檔案",
      UploadFileId_4: "",
      UploadFileSize_4: 0,
      UploadFileName_5: "選擇檔案",
      UploadFileId_5: "",
      UploadFileSize_5: 0,
      UploadFileName_6: "選擇檔案",
      UploadFileId_6: "",
      UploadFileSize_6: 0,
      UploadFileName_7: "選擇檔案",
      UploadFileId_7: "",
      UploadFileSize_7: 0,
      UploadFileSize_Total: 0,
      UploadVideoSize_Total: 0,
      NeedIdent: false,
      cp_lat: 22.994088125212247,
      cp_lng: 120.18488458273286,
      isFirstLoading: true,
      center_position: null,
      mapAddress: "",
      city: "",
      area: "",
      route: "",
      street_number: "",
      lat: 0,
      lng: 0,
      Subj_LatLng: "",
      cp_lat: 22.994088125212247,
      cp_lng: 120.18488458273286,
      isFirstLoading: true,
      center_position: null,
      Cases: null,
      window_open: -1,
      mark_position: null,
      deniedGeolocation: false,
      IgnoreRepeat: false,
      issned: false,
    };
  },
  watch: {
    Sugg_ReplyType(newVal, oldVal) {
      if (newVal != this.pincode_Sugg_ReplyType && !this.CaseAddData.User) {
        this.pincode = "";
        this.pincodeCheck = false;
        this.pincode_input = "";
      }
    },
    Sugg_Telno(newVal, oldVal) {
      if (newVal != this.pincode_Sugg_Telno && !this.CaseAddData.User) {
        this.pincode = "";
        this.pincodeCheck = false;
        this.pincode_input = "";
      }
    },
    Sugg_Email(newVal, oldVal) {
      if (newVal != this.pincode_Sugg_Email && !this.CaseAddData.User) {
        this.pincode = "";
        this.pincodeCheck = false;
        this.pincode_input = "";
      }
    },
    Subj_Item(newVal, oldVal) {
      var needident = $("#Subj_Item").find(":selected").data("needident");
      this.NeedIdent = needident == "1";
    },
    Subj_Location(newVal, oldVal) {
      if (newVal != this.Subj_Location_Last) {
        this.Subj_Location_Last = "";
        this.lat = 0;
        this.lng = 0;
        newVal = "";
      }
    },
    $route(to, from) {
      this.$router.go(0);
    },
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/CaseAdd",
        {
          params: {
            CTIId: this.$route.params.CTIId,
            token: $cookies.get("open_login_token"),
          },
        }
      )
      .then((response) => {
        this.CaseAddData = response.data;
        if (this.CaseAddData.CaseType.VerifiedType != 1) {
          this.Sugg_ReplyType = this.CaseAddData.CaseType.VerifiedType;
        }
        if (this.CaseAddData.User) {
          this.Sugg_Name = this.CaseAddData.User.Name;
          this.Sugg_Sex = this.CaseAddData.User.Sex;
          this.Sugg_Email = this.CaseAddData.User.Email;
          this.Sugg_Telno = this.CaseAddData.User.Phone;
          this.pincodeCheck = true;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    document.title = 'OPEN1999 - 案件通報';
    // 清空座標
    $cookies.remove("op_lat");
    $cookies.remove("op_lon");
    // 迴圈直到取得位置
    var tryTime = 0;
    let that = this;
    var loopLocal = window.setInterval(function () {
      if ($cookies.isKey("op_lat") && $cookies.isKey("op_lon")) {
        var lat = $cookies.get("op_lat");
        var lon = $cookies.get("op_lon");
        that.mark_position = {
          lat: parseFloat(lat),
          lng: parseFloat(lon),
        };
        if (that.isFirstLoading) {
          that.$refs.mapRef.$mapPromise.then((map) => {
            map.setCenter(
              new google.maps.LatLng(
                that.mark_position.lat,
                that.mark_position.lng
              )
            );
            map.setZoom(15);
          });
          that.isFirstLoading = false;
        }
      } else {
        tryTime += 1;
        if (tryTime >= 5) {
          $cookies.set("op_lat", 22.994088125212247);
          $cookies.set("op_lon", 120.18488458273286);
        }
      }
    }, 1000);
    navigator.geolocation.watchPosition(
      function (position) {
        that.deniedGeolocation = false;
        $cookies.set("op_lat", position.coords.latitude);
        $cookies.set("op_lon", position.coords.longitude);
        console.log(
          position.coords.latitude + ", " + position.coords.longitude
        );
      },
      function (error) {
        console.log("code: " + error.code + ", " + "message: " + error.message);
        if (error.code == 1) {
          that.deniedGeolocation = true;
        }
      }
    );
  },
  methods: {
    SubjItemChange: function (event) {
      this.SubCaseTypes = event.target.value;
    },
    GoToStep: function (Step) {
      if (Step == 2) {
        if (this.Subj_Item == -1) {
          Swal.fire({
            title: "錯誤",
            text: "請選擇要通報的事項",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          return;
        }
        if (this.Subj_District == "") {
          Swal.fire({
            title: "錯誤",
            text: "請選擇行政區",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          return;
        }
        // if (this.Subj_Location == "") {
        //   Swal.fire({
        //     title: "錯誤",
        //     text: "請輸入事件發生地點",
        //     icon: "error",
        //     allowOutsideClick: false,
        //     confirmButtonText: "確定",
        //     heightAuto: false,
        //   });
        //   return;
        // }
        if (this.Subj_Content == "") {
          Swal.fire({
            title: "錯誤",
            text: "請輸入反應內容",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          return;
        } else if (this.Subj_Content.length > 1000) {
          Swal.fire({
            title: "錯誤",
            text: "陳情內容如超過1,000字，請以附件檔案上傳。",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          return;
        }
        // 若沒有座標就嘗試取得
        if (this.lat == 0 && this.lng == 0) {
          this.area = $("#Subj_District option:selected").text();
          let that = this;
          var geocoder = new google.maps.Geocoder();
          geocoder
            .geocode({ address: that.area + that.Subj_Location })
            .then((response) => {
              that.center_position = response.results[0].geometry.location;
              that.lat = that.center_position.lat();
              that.lng = that.center_position.lng();

              that.cp_lat = that.lat;
              that.cp_lng = that.lng;

              that.Subj_Location_Last = that.Subj_Location;
              that.Subj_LatLng = that.lat + ", " + that.lng;
            })
            .catch((e) => console.log("Geocoder failed due to: " + e));
        }
        this.Step = Step;
        document.body.scrollTop = 0;
      } else {
        this.Step = Step;
        document.body.scrollTop = 0;
      }
    },
    pincode_input_onInput() {
      if (this.pincode_input != "" && this.pincode_input == this.pincode) {
        this.pincodeCheck = true;
      } else {
        this.pincodeCheck = false;
      }
    },
    getPinCode() {
      if (this.pincodeTime > 0) {
        Swal.fire({
          title: "請稍候",
          html: "重新獲取驗證碼需等待 <span>" + this.pincodeTime + "</span> 秒",
          icon: "info",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (!Validate.reg_phone(this.Sugg_Telno)) {
        Swal.fire({
          title: "錯誤",
          text: "手機號碼格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (this.Sugg_Email == "") {
        Swal.fire({
          title: "錯誤",
          text: "請輸入電子信箱",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      } else if (!Validate.reg_email(this.Sugg_Email)) {
        Swal.fire({
          title: "錯誤",
          text: "電子信箱格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      // 組合並送出
      let token = AES.encrypt(
        this.Sugg_ReplyType + "|" + this.Sugg_Telno + "|" + this.Sugg_Email
      );
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/GetCheckNumber",
        type: "post",
        data: {
          token: token,
        },
        dataType: "json",
        error: function () {},
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            if (res.exist) {
              that.issned = true;
              that.pincode = "檢核碼已驗證, 可直接送出此案件";
              that.pincode_Sugg_ReplyType = that.Sugg_ReplyType;
              that.pincode_Sugg_Telno = that.Sugg_Telno;
              that.pincode_Sugg_Email = that.Sugg_Email;
              that.pincode_input = "檢核碼已驗證, 可直接送出此案件";
              that.pincodeCheck = true;
            } else {
              that.issned = true;
              var strInfo = AES.decrypt(res.PinCode).split("|");
              that.pincode = strInfo[0];
              that.pincode_Sugg_ReplyType = strInfo[1];
              that.pincode_Sugg_Telno = strInfo[2];
              that.pincode_Sugg_Email = strInfo[3];
              // 倒數
              that.pincodeTime = 60;
              //that.pincodeTitle = "重新發送(" + that.pincodeTime + ")";
              var loop = window.setInterval(function () {
                that.pincodeTime -= 1;
                //that.pincodeTitle = "重新發送(" + that.pincodeTime + ")";
                const span = Swal.getHtmlContainer().querySelector("span");
                if (span) {
                  span.textContent = that.pincodeTime;
                }
                if (that.pincodeTime <= 0) {
                  //that.pincodeTitle = "發送檢核碼";
                  window.clearInterval(loop);
                }
              }, 1000);
              Swal.fire({
                title: "已發送驗證碼",
                icon: "success",
                allowOutsideClick: false,
                confirmButtonText: "確定",
                heightAuto: false,
              });
            }
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
      });
    },
    SendCase() {
      if (this.Sugg_Name == "") {
        Swal.fire({
          title: "錯誤",
          text: "請輸入真實姓名",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (this.NeedIdent && this.Sugg_Id == "") {
        Swal.fire({
          title: "錯誤",
          text: "請輸入身分證號",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (!Validate.reg_phone(this.Sugg_Telno)) {
        Swal.fire({
          title: "錯誤",
          text: "手機號碼格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      if (this.Sugg_Email == "") {
        Swal.fire({
          title: "錯誤",
          text: "請輸入電子信箱",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      } else if (!Validate.reg_email(this.Sugg_Email)) {
        Swal.fire({
          title: "錯誤",
          text: "電子信箱格式錯誤",
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
        return;
      }
      // 顯示請稍候
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      // 開始送出
      let that = this;
      let Subj_Subitem = "";
      if ($("#Subj_Subitem_" + this.Subj_Item)) {
        Subj_Subitem = $("#Subj_Subitem_" + this.Subj_Item).val();
      }
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/CaseAdd",
        type: "post",
        data: {
          CTIId: this.$route.params.CTIId,
          Subj_Item: this.Subj_Item,
          Subj_Subitem: Subj_Subitem,
          Subj_District: this.Subj_District,
          Subj_Location: this.Subj_Location,
          Subj_Content: this.Subj_Content,
          Sugg_Name: this.Sugg_Name,
          Sugg_Id: this.Sugg_Id,
          Sugg_Sex: this.Sugg_Sex,
          Sugg_Telno: this.Sugg_Telno,
          Sugg_Email: this.Sugg_Email,
          Register: this.Register,
          Sugg_ReplyType: this.Sugg_ReplyType,
          UploadFileId_1: this.UploadFileId_1,
          UploadFileId_2: this.UploadFileId_2,
          UploadFileId_3: this.UploadFileId_3,
          UploadFileId_4: this.UploadFileId_4,
          UploadFileId_5: this.UploadFileId_5,
          UploadFileId_6: this.UploadFileId_6,
          UploadFileId_7: this.UploadFileId_7,
          Subj_Latitude: this.lat,
          Subj_Longitude: this.lng,
          IgnoreRepeat: this.IgnoreRepeat,
          token: $cookies.get("open_login_token"),
        },
        dataType: "json",
        error: function () {},
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            that.CaseNo = res.CaseNo;
            that.Step = 3;
            document.body.scrollTop = 0;
            Swal.close();
          } else if (res.Repeat) {
            Swal.fire({
              title: "此地點已有相同類型通報",
              text: "是否繼續送出?",
              icon: "info",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              showCancelButton: true,
              cancelButtonText: "取消",
              heightAuto: false,
            }).then((result) => {
              if (result.isConfirmed) {
                that.IgnoreRepeat = true;
                that.SendCase();
              }
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
      });
    },
    fileChange(e, slot) {
      if (e.target.files.length == 0) {
        return;
      }
      // 顯示請稍候
      Swal.fire({
        title: "請稍候...",
        html: "<b>0%</b>",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      // 上傳檔案
      var file = e.target.files[0];
      // 確認副檔名
      if (slot != 4) {
        // 一般檔案
        var re =
          /(\.doc|\.docx|\.xls|\.xlsx|\.pdf|\.txt|\.bmp|\.jpg|\.gif|\.tif|\.png|\.gif|\.jpeg|\.odt|\.ods|\.zip)$/i;
        // 判斷副檔名是否正確
        if (!re.exec(file.name)) {
          Swal.fire({
            title: "錯誤",
            text: "檔案類型錯誤(僅支援 doc、docx、xls、xlsx、pdf、txt、bmp、jpg、gif、tif、png、gif、jpeg、odt、ods、zip)",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          return;
        }
      } else {
        // 影片
        var re = /(\.mp4|\.mov|\.hevc)$/i;
        // 判斷副檔名是否正確
        if (!re.exec(file.name)) {
          Swal.fire({
            title: "錯誤",
            text: "檔案類型錯誤(僅支援mp4、mov或hevc)",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          return;
        }
      }
      // 確認檔案大小
      var totalSize = 0;
      if (slot != 4) {
        totalSize += slot != 1 ? this.UploadFileSize_1 : 0;
        totalSize += slot != 2 ? this.UploadFileSize_2 : 0;
        totalSize += slot != 3 ? this.UploadFileSize_3 : 0;
        totalSize += slot != 5 ? this.UploadFileSize_5 : 0;
        totalSize += slot != 6 ? this.UploadFileSize_6 : 0;
        totalSize += slot != 7 ? this.UploadFileSize_7 : 0;
        if (
          totalSize + file.size >
          this.CaseAddData.LimitFileSize * 1024 * 1024
        ) {
          Swal.fire({
            title: "錯誤",
            text:
              "文件總上傳容量限制為" +
              this.CaseAddData.LimitFileSize +
              "MB, 請確認後再試一次",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          return;
        } else {
          this.UploadFileSize_Total = (
            (totalSize + file.size) /
            1024 /
            1024
          ).toFixed(2);
        }
      } else if (slot == 4) {
        if (file.size > this.CaseAddData.LimitVideoSize * 1024 * 1024) {
          Swal.fire({
            title: "錯誤",
            text:
              "影片上傳容量限制為" +
              this.CaseAddData.LimitVideoSize +
              "MB, 請確認後再試一次",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          return;
        } else {
          this.UploadVideoSize_Total = (file.size / 1024 / 1024).toFixed(2);
        }
      }
      var FileName = file.name;
      // var formData = new FormData();
      // formData.append("file", file);
      let that = this;
      const loadingHtml = Swal.getHtmlContainer().querySelector("b");
      $.ajax({
        type: "POST",
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/_FileUpload" +
          "?fileName=" +
          encodeURI(file.name),
        contentType: "application/octect-stream",
        processData: false, //不做任何處理，只上傳原始資料
        data: file,
        xhr: function () {
          //建立XHR時，加掛onprogress事件
          var xhr = $.ajaxSettings.xhr();
          xhr.upload.onprogress = function (evt) {
            //console.log(evt.loaded);
            loadingHtml.textContent =
              Math.round((evt.loaded / file.size) * 10000) / 100.0 + "%";
          };
          return xhr;
        },
        success: function (result) {
          if (result.ok) {
            switch (slot) {
              case 1:
                that.UploadFileName_1 = FileName;
                that.UploadFileId_1 = result.FileId;
                that.UploadFileSize_1 = result.FileSize;
                break;
              case 2:
                that.UploadFileName_2 = FileName;
                that.UploadFileId_2 = result.FileId;
                that.UploadFileSize_2 = result.FileSize;
                break;
              case 3:
                that.UploadFileName_3 = FileName;
                that.UploadFileId_3 = result.FileId;
                that.UploadFileSize_3 = result.FileSize;
                break;
              case 4:
                that.UploadFileName_4 = FileName;
                that.UploadFileId_4 = result.FileId;
                that.UploadFileSize_4 = result.FileSize;
                break;
              case 5:
                that.UploadFileName_5 = FileName;
                that.UploadFileId_5 = result.FileId;
                that.UploadFileSize_5 = result.FileSize;
                break;
              case 6:
                that.UploadFileName_6 = FileName;
                that.UploadFileId_6 = result.FileId;
                that.UploadFileSize_6 = result.FileSize;
                break;
              case 7:
                that.UploadFileName_7 = FileName;
                that.UploadFileId_7 = result.FileId;
                that.UploadFileSize_7 = result.FileSize;
                break;
            }
            Swal.close();
          } else {
            Swal.fire({
              title: "錯誤",
              text: response.data.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
        error: function () {
          Swal.close();
        },
      });
    },
    clearFile(slot) {
      switch (slot) {
        case 1:
          this.UploadFileName_1 = "選擇檔案";
          this.UploadFileId_1 = "";
          this.UploadFileSize_1 = 0;
          $("#file_1").val("");
          break;
        case 2:
          this.UploadFileName_2 = "選擇檔案";
          this.UploadFileId_2 = "";
          this.UploadFileSize_2 = 0;
          $("#file_2").val("");
          break;
        case 3:
          this.UploadFileName_3 = "選擇檔案";
          this.UploadFileId_3 = "";
          this.UploadFileSize_3 = 0;
          $("#file_3").val("");
          break;
        case 4:
          this.UploadFileName_4 = "選擇檔案";
          this.UploadFileId_4 = "";
          this.UploadFileSize_4 = 0;
          $("#file_4").val("");
          break;
        case 5:
          this.UploadFileName_5 = "選擇檔案";
          this.UploadFileId_5 = "";
          this.UploadFileSize_5 = 0;
          $("#file_5").val("");
          break;
        case 6:
          this.UploadFileName_6 = "選擇檔案";
          this.UploadFileId_6 = "";
          this.UploadFileSize_6 = 0;
          $("#file_6").val("");
          break;
        case 7:
          this.UploadFileName_7 = "選擇檔案";
          this.UploadFileId_7 = "";
          this.UploadFileSize_7 = 0;
          $("#file_7").val("");
          break;
      }
    },
    updateCenter(center) {
      this.$refs.mapRef.$mapPromise.then((map) => {
        this.center_position = map.getCenter();
        // 查詢附近案件
        Vue.axios
          .get(
            this.$store.state.src +
              this.$store.state.subDirectory +
              "/Html/NearbyCase",
            {
              params: {
                s_lat: map.getBounds().getSouthWest().lat(),
                s_lng: map.getBounds().getSouthWest().lng(),
                e_lat: map.getBounds().getNorthEast().lat(),
                e_lng: map.getBounds().getNorthEast().lng(),
              },
            }
          )
          .then((response) => {
            //this.CaseInfo = null;
            this.Cases = response.data.Cases;
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    toggleWindow(index) {
      this.window_open = this.window_open === index ? null : index;
    },
    setAddress() {
      let that = this;
      var geocoder = new google.maps.Geocoder();
      geocoder
        .geocode({ location: this.center_position })
        .then((response) => {
          if (response.results[0]) {
            // 清空
            that.city = "";
            that.area = "";
            that.route = "";
            that.street_number = "";
            $.each(
              response.results[0].address_components,
              function (i, address_component) {
                if (
                  address_component.types[0] == "administrative_area_level_1"
                ) {
                  that.city = address_component.long_name;
                }
                if (
                  address_component.types[0] == "administrative_area_level_2"
                ) {
                  that.area = address_component.long_name;
                }
                if (address_component.types[0] == "route") {
                  that.route = address_component.long_name;
                }
                if (address_component.types[0] == "street_number") {
                  that.street_number = address_component.long_name;
                  if (!that.street_number.endsWith("號")) {
                    that.street_number += "號";
                  }
                }
              }
            );
            that.Subj_Location_Last = that.route + that.street_number;
            that.Subj_Location = that.route + that.street_number;
            if (that.city == "台南市") {
              // 設定區
              if (
                $("#Subj_District").find('option[text="' + that.area + '"]')
              ) {
                that.Subj_District = $("#Subj_District")
                  .find('option[text="' + that.area + '"]')
                  .val();
              }
              // 設定座標
              that.lat = that.center_position.lat();
              that.lng = that.center_position.lng();
              that.Subj_LatLng = that.lat + ", " + that.lng;
            } else {
              // 設定座標
              this.lat = 0;
              this.lng = 0;
              this.Subj_LatLng = "";
            }
          }
        })
        .catch((e) => console.log("Geocoder failed due to: " + e));
      $("#mapModal").modal("toggle");
    },
    // showCaseInfo(Case) {
    //   this.CaseInfo = Case;
    // },
    gotoMyLocation() {
      if (this.deniedGeolocation) {
        Swal.fire({
          title: "無法存取位置資訊",
          html: "請確認您的瀏覽器設定<br />允許本站存取您的位置資訊後再試一次<br />(允許存取後請重新整理此頁面)",
          icon: "info",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
      } else {
        let that = this;
        this.$refs.mapRef.$mapPromise.then((map) => {
          map.setCenter(
            new google.maps.LatLng(
              that.mark_position.lat,
              that.mark_position.lng
            )
          );
          map.setZoom(15);
        });
      }
    },
    setFavorite(isFavorite) {
      // 開始送出
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/SetFavorite",
        type: "post",
        data: {
          CTIId: this.$route.params.CTIId,
          isFavorite: isFavorite,
          token: $cookies.get("open_login_token"),
        },
        dataType: "json",
        error: function () {},
        success: function (res) {
          // 判斷結果
          if (res.ok) {
            that.CaseAddData.IsFavorite = isFavorite;
            Swal.fire({
              title: (isFavorite ? "加入" : "移除") + "完成",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        },
      });
    },
  },
};
</script>

<style scoped>
@media (max-width: 767.98px) {
  .new-case .box .form-group input {
    width: 100% !important;
  }
}
</style>
